

body {
    font-family: 'Syne', sans-serif;
    background: black;
    overflow-x: hidden;
    color: white;
}

.white-text {
    color: white;
}

.cursor-point {
    cursor: pointer;
}

.navbar-brand {
    text-transform: uppercase;
    font-weight: bold;
}

.bg-dark-j {
    background-color: black;
    color: white;
}

.bg-emph {
    background-color: rgb(255, 41, 205);
    border-color: rgb(184, 23, 146);
}

.bg-dark-j-faded {
    background-color: rgba(0,0,0, 0.5);
    color: white;
}

.bg-light-j {
    background-color: lightgray;
    color:black;
}

.text-emph {
    color: rgb(255, 41, 205);
}

.col-header {
    font-weight: bold;
    color: rgba(37,44,54,0.7);
}

.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 600ms ease-in 600ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 600ms ease-in;
}

.landing-button {
    text-transform: uppercase;
    font-size: 2rem;
    color: white;
    background-color: rgba(95, 20, 20, 0.5);
    font-weight: bold;
    text-align: center;
    padding: 2% 0% 2% 0%;
    height:10vh;
    transition: all 400ms ease-in-out;
    animation-duration: 5000ms;
    animation-timing-function: ease-in;
    animation-name: fade-in;
    animation-iteration-count: infinite;
}

.landing-button:hover {
    text-transform: uppercase;
    font-size: 2.4rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
    height:30vh;
    animation:none;
}

.landing-section-body {
    min-height: 79vh;
}

.landing-section-header {
    min-height: 20vh;
}

@keyframes fade-in {
    0% {
        background-color: rgba(95, 20, 20, 0.3);
    }
    50% {
        background-color: rgba(95, 20, 20, 0.8);
    }
    100% {
        background-color: rgba(95, 20, 20, 0.3);
    }
}

.landing-col {
    padding: 0%;
    display:flex;
    flex-Direction:column;
    justify-Content:flex-end;
    
}

.card {
    margin:0%;
}

.carousel {
    box-shadow: 1px 1px 10px 3px rgba(0,0,0,0.5);
}

.full-width {
    width: 100%;
}



#spotify-button {
    background-color: black;
    background-image: url('./assets/img/Spotify_Logo_RGB_Green.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100px;
}
#spotify-button-white {
    background-color: black;
    background-image: url('./assets/img/Spotify_Logo_RGB_White.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100px;
}

#apple-button {
    background-color: black;
    background-image: url('./assets/svg/Apple/standard-white.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 125px;
}

#pandora-button {
    background-color: black;
    background-image: url('./assets/svg/Pandora_wordmark.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100px;
}

#youtube-button {
    background-color: black;
    background-image: url('./assets/img/yt_logo_rgb_dark.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100px;
}

#amazon-button {
    background-color: black;
    background-image: url('./assets/img/amazonmusic-logo-white.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 105px;
}

#tidal-button {
    background-color: black;
    background-image: url('./assets/img/tidal-large-white.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 105px;
}

#landingName {
    font-size: 4rem;
    width: 100%;
}

#landingNameContainer {
    flex-direction: column;
    width:100%;
}

#landingContainerImage {
    background-image: url('./assets/img/CapitalPride2023-01.jpg');
    background-repeat: no-repeat;
    background-position-y: 30%;
    background-position-x: center;
    background-size: cover;
}

.text-bg-black {
    background-color: black;
}

.big-words {
    font-size: 3rem;
}

.bigger-words {
    font-size: 4rem;
}

.featured-album {
    max-width: 45%;
}

.all-caps {
    text-transform: uppercase;
}


.music-button {
    width: 250px;
    height: 60px;
    box-shadow: 1px 1px 10px 3px rgba(0,0,0,0.5);
    border: 1px solid #9c9c9c;
    border-radius: 12px; 
    margin-bottom: 2%;
    margin-top: 2%;
    margin-left: 0%;
    margin-right: 0%;
}

.music-button:hover {
    box-shadow: none;
    cursor: pointer;
}

.site-logo {
    width: 250px;
    height: 60px;
}

.site-border {
    border-top: 1px solid darkgray;
}

#family-carousel {
    width:100%;
}

.levengood-logo {
    width:100%;
}
.modal-dialog-custom {
    width:100vw;
    max-height: 100vh;
    max-width: 100vw;
}

#bookingTitle {
    font-size: 4rem;
}

@media  screen and (min-width: 992px) {
    #navMenuButton {
        visibility: hidden;
        display: none;
    }
}

@media  screen and (min-width: 320px) and (max-width: 640px) {

    .landing-button {
        text-align: left;
        
    }
    
    .landing-button:hover {
        text-align: center;
    }
    
    .landing-col {
        height: 10%;
        padding: 5%;
    }

    .mobile-nav-text {
        font-size:2rem;
    }
    #landingName {
        font-size: 3rem;
        width: 90%;
        
    }

    #landingNameContainer {
        flex-wrap: wrap;
        position: absolute;
        top: 55vh;
        left: 0vw;
        
    }

    #bookingTitle {
        font-size: 1.4rem;
    }

}

.no-scroll-y {
    overflow-y: hidden;
}

.black {
    background-color: black;
}
.brown {
    background-color: brown;
}
.red {
    background-color: red;
}
.orange {
    background-color: orange;
}
.yellow {
    background-color: yellow;
}
.green {
    background-color: green;
}
.blue {
    background-color: blue;
}
.purple {
    background-color: blueviolet;
}

.background-headshot {
    background: center/cover no-repeat url('./assets/img/pride-headshot.jpg') darkgray;
}

#backgroundKaraokeBanner {
    background: center/cover no-repeat url('./assets/img/werkethicbanner02.jpg') darkgray;
}

.background-popparty-banner {
    background: center/cover no-repeat url('./assets/img/dirtypoppartyad.jpg') darkgray;
}

.music-logo {
    box-shadow: 0px 0px 10px 1px rgb(134, 134, 134);
    border-radius: 15px;
}

.music-logo:hover {
    box-shadow: 0px 0px 10px 1px rgb(207, 207, 207);
}

.pride-background {
    background: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(92,75,46,0.5) 10%, rgba(255,0,0,0.5) 20%, rgba(254,112,10,0.5) 30%, rgba(253,254,16,0.5) 40%, rgba(42,208,50,0.5) 50%, rgba(53,84,203,0.5) 60%, rgba(138,22,195,0.5) 70%, rgba(23,255,237,0.5) 80%, rgba(209,133,192,0.5) 90%, rgba(0,0,0,0.5) 100%);
}

.pride-bg-75 {
    background: linear-gradient(90deg, rgba(0,0,0,0.75) 0%, rgba(92,75,46,0.75) 10%, rgba(255,0,0,0.75) 20%, rgba(254,112,10,0.75) 30%, rgba(253,254,16,0.75) 40%, rgba(42,208,50,0.75) 50%, rgba(53,84,203,0.75) 60%, rgba(138,22,195,0.75) 70%, rgba(23,255,237,0.75) 80%, rgba(209,133,192,0.75) 90%, rgba(0,0,0,0.75) 100%);
}

.text-strong {
    color:white;
    font-weight: bold;
    text-transform:uppercase;
    text-shadow: 0 0 5px #00ffff, 0 0 10px #0000ff;
}

.text-glow-neon {
    color:white;
    text-shadow: 0 0 5px #00ffff, 0 0 10px #0000ff;
}

.text-glow-pink {
    text-shadow: 0 0 5px #ff00dd, 0 0 10px #0000ff;
}

.album-art {
    width:100%;
    box-shadow: 1px 1px 5px black;
}